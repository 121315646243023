.layers {
  position: relative;
  /* top: 0; */
  /* top: 10rem; */
  height: 500px;
  width: 500px;
}

.layers img,
.layers div {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
}

.layers .background {
  height: 100%;
  width: 100%;
  background: gray;
}

@media (max-width: 1024px) {
  .layers {
    height: 300px;
    width: 300px;
  }
}
