.team-item .bobble-wrapper {
  height: 400px;
  width: 400px;
}

.team-item .description {
  width: 400px;
}

.description {
  min-height: 124px;
}

@media (max-width: 1200px) {
  .team-item .bobble-wrapper {
    height: 300px;
    width: 300px;
  }
  .team-item .description {
    width: 300px;
  }
}

@media (max-width: 600px) {
  .team-item .bobble-wrapper {
    height: 300px;
    width: 300px;
  }
  .team-item .description {
    width: 300px;
  }
}
