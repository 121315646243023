.vertical-timeline-element-icon {
  display: flex;
  padding: 26px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.vertical-timeline-element-content {
  border-radius: 0;
}
