@media (max-width: 600px) {
  video {
    width: 300px;
    margin: 0 auto;
  }
}

.shadow-text {
  text-shadow: 1px 2px 2px #ffffff, -1px -2px 2px #ffffff;
}
