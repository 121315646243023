.header .bobble-wrapper {
  height: 400px;
  width: 400px;
}

@media (max-width: 1200px) {
  .header .bobble-wrapper {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 768px) {
  .header {
    min-height: 70vh;
  }
}

@media (max-width: 600px) {
  .header .bobble-wrapper {
    height: 150px;
    width: 150px;
  }
}
