.rowController {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.colController {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.partnerImgController {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 3px solid #000000;
  border-radius: 0px 10px 10px 10px;
  margin-bottom: 2rem;
  padding: 1.2rem;
}

.partnerImg {
  margin: 0.5rem;
}

.tag {
  background: #b45309;
  border-radius: 12.5px 12.5px 0px 0px;
  width: 7rem;
  color: white;
  padding: 0 1rem;
}

.partnerName {
  color: #000000;
  text-align: center;
}
