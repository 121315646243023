.bobble-wrapper-carousel {
  height: 400px;
  width: 400px;
}

.carousel-item {
  background: transparent;
}

@media (max-width: 1200px) {
  .bobble-wrapper-carousel {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 600px) {
  .bobble-wrapper-carousel {
    height: 300px;
    width: 300px;
  }
}
